define("sharedrop/templates/components/user-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7/SMpfeH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"avatar\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,0,\"gravatar\"],[15,\"src\",[34,0,[\"avatarUrl\"]]],[15,\"alt\",[34,1,[\"label\"]]],[15,\"title\",[31,[\"peer id: \",[34,0,[\"uuid\"]]]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"user-info\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"user-label\"],[12],[2,\"You\"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"user-ip\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[1,[35,0,[\"label\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"user\",\"users\"]}",
    "meta": {
      "moduleName": "sharedrop/templates/components/user-widget.hbs"
    }
  });

  _exports.default = _default;
});