define("sharedrop/routes/index", ["exports", "jquery", "sharedrop/services/room"], function (_exports, _jquery, _room) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel() {
      const {
        error
      } = window.Sharedrop;

      if (error) {
        throw new Error(error);
      }
    },

    model() {
      // Get room name from the server
      return _jquery.default.getJSON('/room').then(data => data.name);
    },

    setupController(ctrl, model) {
      ctrl.set('model', []);
      ctrl.set('hasCustomRoomName', false); // Handle room events

      _jquery.default.subscribe('connected.room', ctrl._onRoomConnected.bind(ctrl));

      _jquery.default.subscribe('disconnected.room', ctrl._onRoomDisconnected.bind(ctrl));

      _jquery.default.subscribe('user_added.room', ctrl._onRoomUserAdded.bind(ctrl));

      _jquery.default.subscribe('user_changed.room', ctrl._onRoomUserChanged.bind(ctrl));

      _jquery.default.subscribe('user_removed.room', ctrl._onRoomUserRemoved.bind(ctrl)); // Handle peer events


      _jquery.default.subscribe('incoming_peer_connection.p2p', ctrl._onPeerP2PIncomingConnection.bind(ctrl));

      _jquery.default.subscribe('incoming_dc_connection.p2p', ctrl._onPeerDCIncomingConnection.bind(ctrl));

      _jquery.default.subscribe('incoming_dc_connection_error.p2p', ctrl._onPeerDCIncomingConnectionError.bind(ctrl));

      _jquery.default.subscribe('outgoing_peer_connection.p2p', ctrl._onPeerP2POutgoingConnection.bind(ctrl));

      _jquery.default.subscribe('outgoing_dc_connection.p2p', ctrl._onPeerDCOutgoingConnection.bind(ctrl));

      _jquery.default.subscribe('outgoing_dc_connection_error.p2p', ctrl._onPeerDCOutgoingConnectionError.bind(ctrl));

      _jquery.default.subscribe('disconnected.p2p', ctrl._onPeerP2PDisconnected.bind(ctrl));

      _jquery.default.subscribe('info.p2p', ctrl._onPeerP2PFileInfo.bind(ctrl));

      _jquery.default.subscribe('response.p2p', ctrl._onPeerP2PFileResponse.bind(ctrl));

      _jquery.default.subscribe('file_canceled.p2p', ctrl._onPeerP2PFileCanceled.bind(ctrl));

      _jquery.default.subscribe('file_received.p2p', ctrl._onPeerP2PFileReceived.bind(ctrl));

      _jquery.default.subscribe('file_sent.p2p', ctrl._onPeerP2PFileSent.bind(ctrl)); // Join the room


      const room = new _room.default(model, window.Sharedrop.ref);
      room.join(ctrl.get('you').serialize());
      ctrl.set('room', room);
    },

    renderTemplate() {
      this.render();
      this.render('about_you', {
        into: 'application',
        outlet: 'about_you'
      });
      const key = 'show-instructions-for-app';

      if (!localStorage.getItem(key)) {
        this.send('openModal', 'about_app');
        localStorage.setItem(key, 'yup');
      }
    },

    actions: {
      willTransition() {
        _jquery.default.unsubscribe('.room');

        _jquery.default.unsubscribe('.p2p'); // eslint-disable-next-line ember/no-controller-access-in-routes


        const controller = this.controllerFor('index');
        controller.get('room').leave();
        return true;
      }

    }
  });

  _exports.default = _default;
});