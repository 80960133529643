define("sharedrop/templates/errors/popovers/connection-failed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G3G10YD7",
    "block": "{\"symbols\":[],\"statements\":[[2,\"It was not possible to establish direct connection with the other peer.\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "sharedrop/templates/errors/popovers/connection-failed.hbs"
    }
  });

  _exports.default = _default;
});