define("sharedrop/components/circular-progress", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <svg width="76" height="76" viewport="0 0 76 76" style={{this.style}}>
    <path class="break" transform="translate(38, 38)" d={{this.path}} />
  </svg>
  */
  {"id":"PyuhpFmm","block":"{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"width\",\"76\"],[14,\"height\",\"76\"],[14,\"viewport\",\"0 0 76 76\"],[15,5,[32,0,[\"style\"]]],[12],[2,\"\\n  \"],[10,\"path\"],[14,0,\"break\"],[14,\"transform\",\"translate(38, 38)\"],[15,\"d\",[32,0,[\"path\"]]],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}","meta":{"moduleName":"sharedrop/components/circular-progress.hbs"}});

  const COLORS = {
    blue: '0, 136, 204',
    orange: '197, 197, 51'
  };

  class CircularProgress extends _component.default {
    constructor(owner, args) {
      super(owner, args);
      const rgb = COLORS[args.color];
      this.style = Ember.String.htmlSafe(`fill: rgba(${rgb}, .5)`);
    }

    get path() {
      const π = Math.PI;
      const α = this.args.value * 360;
      const r = α * π / 180;
      const mid = α > 180 ? 1 : 0;
      const x = Math.sin(r) * 38;
      const y = Math.cos(r) * -38;
      return `M 0 0 v -38 A 38 38 1 ${mid} 1 ${x} ${y} z`;
    }

  }

  _exports.default = CircularProgress;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CircularProgress);
});