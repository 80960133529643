define("sharedrop/templates/errors/browser-unsupported", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Za00Hs1z",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"error\"],[12],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"We're really sorry, but your browser is not supported.\"],[10,\"br\"],[12],[13],[2,\"Please use the latest desktop or Android version of\"],[10,\"br\"],[12],[13],[10,\"b\"],[12],[2,\"Chrome\"],[13],[2,\", \"],[10,\"b\"],[12],[2,\"Opera\"],[13],[2,\" or \"],[10,\"b\"],[12],[2,\"Firefox\"],[13],[2,\".\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "sharedrop/templates/errors/browser-unsupported.hbs"
    }
  });

  _exports.default = _default;
});