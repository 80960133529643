define("sharedrop/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller) {
      controller.set('currentRoute', this);
    },

    actions: {
      openModal(modalName) {
        return this.render(modalName, {
          outlet: 'modal',
          into: 'application'
        });
      },

      closeModal() {
        return this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      }

    }
  });

  _exports.default = _default;
});