define("sharedrop/templates/errors/popovers/multiple-files", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ycMD5lMo",
    "block": "{\"symbols\":[],\"statements\":[[2,\"The files you have selected exceed the maximum allowed size of 200MB\\n\"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\"\\nTIP: You can send single files without size restriction\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "sharedrop/templates/errors/popovers/multiple-files.hbs"
    }
  });

  _exports.default = _default;
});