define("sharedrop/app", ["exports", "ember-resolver", "ember-load-initializers", "sharedrop/config/environment", "@sentry/browser", "@sentry/integrations"], function (_exports, _emberResolver, _emberLoadInitializers, _environment, Sentry, _integrations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  Sentry.init({
    dsn: 'https://ba1292a9c759401dbbda4272f183408d@o432021.ingest.sentry.io/5384091',
    integrations: [new _integrations.Ember()]
  });

  class App extends Ember.Application {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "modulePrefix", _environment.default.modulePrefix);

      _defineProperty(this, "podModulePrefix", _environment.default.podModulePrefix);

      _defineProperty(this, "Resolver", _emberResolver.default);
    }

  }

  _exports.default = App;
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
});