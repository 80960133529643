define("sharedrop/templates/errors/filesystem-unavailable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t01tsazA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"error\"],[12],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"Uh oh. Looks like there's some issue and we won't be able\"],[10,\"br\"],[12],[13],[2,\"to save your files.\"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"If you've opened this app in incognito/private window,\"],[10,\"br\"],[12],[13],[2,\"try again in a normal one.\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "sharedrop/templates/errors/filesystem-unavailable.hbs"
    }
  });

  _exports.default = _default;
});