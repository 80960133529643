define("sharedrop/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NIu73Iwu",
    "block": "{\"symbols\":[\"peer\"],\"statements\":[[10,\"main\"],[14,0,\"l-content\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"user others\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,4],null,[[\"peer\",\"hasCustomRoomName\",\"webrtc\"],[[32,1],[35,3],[35,2]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[6,[37,8],[[35,0,[\"uuid\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"user you\"],[12],[2,\"\\n      \"],[1,[30,[36,1],null,[[\"user\"],[[35,0]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"svg\"],[14,0,\"circles\"],[14,\"viewBox\",\"-0.5 -0.5 1140 700\"],[12],[2,\"\\n    \"],[10,\"circle\"],[14,0,\"circle\"],[14,\"cx\",\"570\"],[14,\"cy\",\"570\"],[14,\"r\",\"30\"],[14,\"stroke\",\"rgba(160,160,160, 1)\"],[12],[13],[2,\"\\n    \"],[10,\"circle\"],[14,0,\"circle\"],[14,\"cx\",\"570\"],[14,\"cy\",\"570\"],[14,\"r\",\"100\"],[14,\"stroke\",\"rgba(160,160,160,.9)\"],[12],[13],[2,\"\\n    \"],[10,\"circle\"],[14,0,\"circle\"],[14,\"cx\",\"570\"],[14,\"cy\",\"570\"],[14,\"r\",\"200\"],[14,\"stroke\",\"rgba(160,160,160,.8)\"],[12],[13],[2,\"\\n    \"],[10,\"circle\"],[14,0,\"circle\"],[14,\"cx\",\"570\"],[14,\"cy\",\"570\"],[14,\"r\",\"300\"],[14,\"stroke\",\"rgba(160,160,160,.7)\"],[12],[13],[2,\"\\n    \"],[10,\"circle\"],[14,0,\"circle\"],[14,\"cx\",\"570\"],[14,\"cy\",\"570\"],[14,\"r\",\"400\"],[14,\"stroke\",\"rgba(160,160,160,.6)\"],[12],[13],[2,\"\\n    \"],[10,\"circle\"],[14,0,\"circle\"],[14,\"cx\",\"570\"],[14,\"cy\",\"570\"],[14,\"r\",\"500\"],[14,\"stroke\",\"rgba(160,160,160,.5)\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"you\",\"user-widget\",\"webrtc\",\"hasCustomRoomName\",\"peer-widget\",\"model\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "sharedrop/templates/index.hbs"
    }
  });

  _exports.default = _default;
});