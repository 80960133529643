define("sharedrop/components/file-field", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.TextField.extend({
    type: 'file',
    classNames: ['invisible'],

    click(event) {
      event.stopPropagation();
    },

    change(event) {
      const input = event.target;
      const {
        files
      } = input;
      this.onChange({
        files
      });
      this.reset();
    },

    // Hackish way to reset file input when sender cancels file transfer,
    // so if sender wants later to send the same file again,
    // the 'change' event is triggered correctly.
    reset() {
      const field = (0, _jquery.default)(this.element);
      field.wrap('<form>').closest('form').get(0).reset();
      field.unwrap();
    }

  });

  _exports.default = _default;
});