define("sharedrop/initializers/prerequisites", ["exports", "jquery", "sharedrop/config/environment", "sharedrop/services/file", "sharedrop/services/analytics"], function (_exports, _jquery, _environment, _file, _analytics) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /* jshint -W030 */
  function initialize(application) {
    function checkWebRTCSupport() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        // window.util is a part of PeerJS library
        if (window.util.supports.sctp) {
          resolve();
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('browser-unsupported');
        }
      });
    }

    function clearFileSystem() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        // TODO: change File into a service and require it here
        _file.default.removeAll().then(() => {
          resolve();
        }).catch(() => {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('filesystem-unavailable');
        });
      });
    }

    function authenticateToFirebase() {
      return new Ember.RSVP.Promise((resolve, reject) => {
        const xhr = _jquery.default.getJSON('/auth');

        xhr.then(data => {
          const ref = new window.Firebase(_environment.default.FIREBASE_URL); // eslint-disable-next-line no-param-reassign

          application.ref = ref; // eslint-disable-next-line no-param-reassign

          application.userId = data.id; // eslint-disable-next-line no-param-reassign

          application.publicIp = data.public_ip;
          ref.authWithCustomToken(data.token, error => {
            if (error) {
              reject(error);
            } else {
              resolve();
            }
          });
        });
      });
    } // TODO: move it to a separate initializer


    function trackSizeOfReceivedFiles() {
      _jquery.default.subscribe('file_received.p2p', (event, data) => {
        _analytics.default.trackEvent('received', {
          event_category: 'file',
          event_label: 'size',
          value: Math.round(data.info.size / 1000)
        });
      });
    }

    application.deferReadiness();
    checkWebRTCSupport().then(clearFileSystem).catch(error => {
      // eslint-disable-next-line no-param-reassign
      application.error = error;
    }).then(authenticateToFirebase).then(trackSizeOfReceivedFiles).then(() => {
      application.advanceReadiness();
    });
  }

  var _default = {
    name: 'prerequisites',
    initialize
  };
  _exports.default = _default;
});