define("sharedrop/components/popover-confirm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['popover-confirm'],
    iconClass: Ember.computed('filename', function () {
      const {
        filename
      } = this;

      if (filename) {
        const regex = /\.([0-9a-z]+)$/i;
        const match = filename.match(regex);
        const extension = match && match[1];

        if (extension) {
          return `glyphicon-${extension.toLowerCase()}`;
        }
      }

      return undefined;
    }),
    actions: {
      confirm() {
        this.onConfirm();
      },

      cancel() {
        this.onCancel();
      }

    }
  });

  _exports.default = _default;
});